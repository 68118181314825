import axios from 'axios'
import environment from '@/configs/environtment'
import setup from './setupInterceptor'

const API_URL = environment.getApiUrl()
console.log('url', API_URL)

// const API_URL = 'https://dummyjson.com'
const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: '*/*',
  },
})

api.interceptors.request.use((config: any) => config, (error: any) => Promise.reject(error))

setup(api)

export default api
