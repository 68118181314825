import store from '@/store'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import {  useRouter } from 'vue-router'

const router = useRouter()
const endpoint = 'openapi/v1/Identity/Refresh'

const setup = (api: any) => {
  api.interceptors.response.use(async (response: any) => response, async (err: any) => {
    console.log('mamen', err)
    if (err.response.status === 401) {
      const token = localStorage.getItem('ut')
      const timeExpired = moment(localStorage.getItem('ei'))
      const getRefreshToken = localStorage.getItem('rt')
      if (token && timeExpired && getRefreshToken) {
        const payload = {
          refreshToken: getRefreshToken
        }
        await apiUseCase.postOrPut(endpoint, null, payload).then((res) => {
          store.dispatch('auth/setTokenAuth', res.result)
        })
        router.go(0)
      } else {
        store.dispatch('logoutAccount')
      }
    }
    return Promise.reject(err)
  })
}

export default setup
