/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@/configs/api'
import { IRemote } from './iRemote'
import remoteEnum from './enumActions'

class Remote implements IRemote {
  method: remoteEnum

  endPoint: string
  
  headers: any
  
  data: any

  constructor(method: remoteEnum, endPoint: string, data: any = null, headers: Record<string, string> = {}) {
    this.method = method
    this.endPoint = endPoint
    this.data = data
    
    this.headers = {
      ...headers, // Allow dynamic headers
      timeout: 5000 // Default timeout, can be overridden
    }
  
    const ut = localStorage.getItem('ut')
    if (ut) {
      this.headers.authorization = `Bearer ${ut}` // Set default authorization if available
    }
  }

  get() {
    return api.get(this.endPoint, { headers: this.headers })
  }

  post() {
    return api.post(this.endPoint, this.data, { headers: this.headers })
  }

  put() {
    return api.put(this.endPoint, this.data, { headers: this.headers })
  }

  patch() {
    return api.patch(this.endPoint, this.data, { headers: this.headers })
  }

  delete() {
    return api.delete(this.endPoint, { headers: this.headers })
  }

  run() {
    switch (this.method) {
      case remoteEnum.get:
        return this.get()
      case remoteEnum.post:
        return this.post()
      case remoteEnum.patch:
        return this.patch()
      case remoteEnum.put:
        return this.put()
      case remoteEnum.delete:
        return this.delete()
      default:
        return []
    }
  }
}

export { Remote, remoteEnum }
